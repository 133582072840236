import React from 'react';
//import Layout from '../components/layout';
import SEO from '../components/seo';
import { MDBContainer } from 'mdbreact';
import Pageheader from '../Utils/pageheader';
const NewsLetter = () => {
    return (
        <>
            <SEO title="News Letter" />
            <MDBContainer className="mt-4">
                <h3 className="title font-weight-bolder text-secondary">News Letter</h3>
                <Pageheader />
                <iframe title="newsletter" src="https://docs.google.com/forms/u/0/d/e/1FAIpQLSecqQDshVoS0qGUQY76rs1QZX_IiFHeJclyi8QIBYxXCZUduA/formResponse?embedded=true" width="100%" style={{ minHeight: "1200px" }} frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
            </MDBContainer>
        </>
    );
};

export default NewsLetter;